import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { Container, H1, H2, H3, Text } from "../global-styles"

const BlogPost = ({ data }) => {

    const twH1 = props => <H1 {...props} />
    const twH2 = props => <H2 {...props} />
    const twH3 = props => <H3 {...props} />
    const twText = props => <Text {...props} />

    const components = {
        h1: twH1,
        h2: twH2,
        h3: twH3,
        p: twText
    }

    return (
        <Layout>
            <MDXProvider components={components}>
                <Container className="flex flex-col w-full md:w-3/4 lg:w-1/2">
                    <H1>{data.mdx.frontmatter.title}</H1>
                    <Text className="text-gray-dark">{data.mdx.frontmatter.displayeddate}</Text>
                    <Text>{data.mdx.frontmatter.summary}</Text>
                    <MDXRenderer>
                        {data.mdx.body}
                    </MDXRenderer>
                </Container>
            </MDXProvider>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String) {
        mdx(slug: {eq: $slug}) {
            id
            body
            frontmatter {
                title
                summary
                displayeddate
      }
    }
  }
`

export default BlogPost